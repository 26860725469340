const StringUtility = {
    'SERVICE1': 'CELTIC CROSS TAROT SPREAD',
    'SERVICE1_DESCRIPTION': '<p><b>20 – 30 minutes $45.00</b></p> <p>The Celtic Cross Reading is the most commonly used tarot spread.  This 10 card reading is intended to provide insight and advice on a current life challenge or situation being faced by the Querent.  Virtual reading over Zoom or Google Meet.  * In person readings available on some weeknights and Sunday afternoons if scheduled at Moto Vino in Babylon Village.</p>',

    'SERVICE2': 'ELEMENTAL INSIGHT READING',
    'SERVICE2_DESCRIPTION': '<p><b>45 – 60 minutes $65.00</b></p> <p>Reading will start with a 3 card pull to help Querent with choosing an area of life interest they would like more insight on, followed by an in depth elemental reading focusing on one of the four elements represented in the tarot:<br><br> <b>Pentacles</b> “Earth” – Home, Finances & Security <br><b>Swords</b> “Air “– Work /Career, Anxiety, Conflict, Intellect <br><b>Cups</b> “Water” – Relationships, Romance, Love and Emotion <br><b>Wands</b> “Fire” – Health, Energy, Passion and Motivation <br><br>Virtual reading over Zoom or Google Meet.  * In person readings available on some weeknights and Sunday afternoons if scheduled at Moto Vino in Babylon Village.</p>',

    'SERVICE3': 'EVENTS & PARTIES',
    'SERVICE3_DESCRIPTION': '<p><b>2 hour minimum $100.00 per hour</b> </p><p>*Travel costs may apply if outside the Babylon, NY Township area.  Virtual party formats can also be arranged. </p> <p>A two hour party can currently accommodate 12 – 16 guest readings with quick  five – ten minute readings. For parties with under (10) guests, longer 10 – 15 minute readings can be provided.</p>',

    'SERVICE4': 'BIRTHDAY & ANNIVERSARY SPREADS',
    'SERVICE4_DESCRIPTION': '<p><b>45 – 60 minutes $65.00</b></p>Take time to reflect on a birthday or special anniversary with this 12-card spread which covers a summary and reflection from the past year and looks ahead on themes and lessons upcoming for the year ahead.<br><br>Virtual reading over Zoom or Google Meet. In person readings available on some weeknights and Sunday afternoons if scheduled at Moto Vino in Babylon Village.</p>',

    'ABOUT': "<p>Jennifer, based in Long Island, NY, is an intuitive tarot reader. She holds a Master's degree in Humanistic and Multicultural Education from the State University of New York at New Paltz. This degree fueled her lifelong passion for self-reflection, creativity, and understanding the influence of the subconscious mind on daily life. Jennifer uses her knowledge of tarot cards and their symbolism to engage in creative conversations, helping clients gain clarity on their current situations from an alternative viewpoint. Through the tarot reading process, she identifies potential obstacles and explores opportunities for shifts in perspective that can restore a sense of forward movement, life flow, and purpose.</p><p> Jennifer is empathetic, positive, and excels as a guide through tarot symbolism, creative conversation, and the subconscious mind. Her intuitive and relatable reading style is influenced by authors such as Melissa Cynova (Tarot Elements and Kitchen Table Tarot), Tina Gong (Tarot: Connect with Yourself, Develop Your Intuition, Live Mindfully), and Jessica Dore (Tarot for Change: Using the Cards for Self-Care, Acceptance, and Growth).</p>",
    
   

    'TERMS_OF_SERVICE': `<h3>Queen Of Wands Tarot Advising, LTD</h3>
    <h3>Established – May 2024</h3>
    <h4>Payments and Terms of Service</h4>
    </br>
    <p><strong>Payment Terms.</strong> For all readings under $75.00, the full fee is due at the time your request is made prior to the reading. 
    Payment must be received by <strong><em>Queen of Wands Tarot Advising, LTD </strong></em> before the commencement of your reading. For both virtual
     and in person events, a 20% deposit is due at the time the event is scheduled to reserve your date and time. For events, an invoice with agreement 
     details and any applicable miscellaneous and travel fees will be sent to client. Within 24 hours prior to the scheduled event, full payment must be 
     tendered to <strong><em> Queen of Wands Tarot Advising, LTD. </em></strong> Due to the difficulty with debt collection, all payments for reading 
     requests must be tendered in advance.</p>

    <p><strong>Payment Methods.</strong> For online readings, <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> accepts Venmo orPayPal. 
    All payment transfers are done through the third party platform Venmo or PAYPAL, so please be familiar with the privacy policy and terms of use for 
    these third party platforms. <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> is not liable for any mishandling by these third-party 
    platforms. For in-person readings, Venmo or cash is accepted.</p>

    <p><strong><em>*</em></strong>In some cases, checks from corporations or established institutions are accepted and can be paid via instructions on 
    invoice provided by <strong><em>Queen of Wands Tarot Advising, LTD.</strong></em></p>

    <p><strong>Refund Policy:</strong> Tarot readings by <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> constitute a professional service and 
    due to the nature of the service and labor involved, all readings are non-refundable. However, if no reading was performed or your tarot professional 
    has cancelled the reading prior to its commencement, then of course, your fee will be refunded to you in full. <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> 
    exercises great reasonable care and diligence in performing each and every tarot reading. If you are dissatisfied with your reading, please contact your 
    tarot professional to see if an agreeable settlement may be worked out.</p>


    <p><strong>Scope of Services and Disclosure of Limitations.</strong> The tarot reading services provided by <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> 
    are intended to function as creative advising services, helping you explore your goals for the future and shape the life path you desire. These services 
    offer innovative perspectives to understand your current challenges and suggest actions you can take to empower yourself, thereby enhancing your 
    personal and professional potential. The role of such advising is supportive and strives to help you improve the quality of your life by tapping into 
    your intuition.</p>

   <p>However, your tarot reading with <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> is not a substitute for professional counseling from a 
   licensed therapist or counselor and should not be relied upon for sound legal, medical, or financial advice from qualified professionals. The services 
   provided do not include diagnosing problems but rather offering supportive guidance. You should not rely on a tarot reading to make decisions that 
   would affect your legal, financial, or medical condition. If your inquiry involves legal, financial, or medical concerns, you should seek advice from a 
   licensed or qualified professional. A tarot reading is also not a replacement for qualified mental health care; it is intended to facilitate how you 
   cope spiritually with a given situation.</p>

   <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> shall not be held liable for any damages, losses, or claims arising from the use or 
   reliance on the services provided, which are intended for advisory and entertainment purposes only. By engaging with these services, the client 
   acknowledges that Queen of Wands Tarot Advising, LTD is not responsible for decisions or actions taken based on the provided insights, and clients 
   should seek professional advice for legal, medical, or financial matters.</p>


   <p><strong>Confidentiality of Tarot Readings.</strong> <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> exercises reasonable diligence in 
   maintaining the confidentiality of your tarot reading and will not voluntarily, knowingly, intentionally, or willfully disclose your identity or the 
   contents of your tarot reading to third parties. However, if <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> genuinely believes disclosure 
   is required to: (1) prevent imminent harm or risk of harm to yourself or to another, (2) meet any applicable law, regulation, legal process, or 
   governmental request, or (3) protect or defend <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> against claims or threats of claims, then 
   <strong><em>Queen of Wands Tarot Advising, LTD</strong></em> reserves the right to do so to the extent reasonably necessary. Please keep in mind that 
   no legally recognized privilege exists between a tarot professional and client the way a privilege might exist between an attorney and client, doctor 
   and patient, husband and wife, or priest and penitent.</p>


   <p><strong><em>BY CONTACTING YOUR NAME OR YOUR BUSINESS NAME FOR A READING, YOU ARE IN EFFECT ACKNOWLEDGING AND AGREEING TO THE FOREGOING TERMS OF 
   SERVICE.</strong></em></p>`
}

//Object.freeze(MESSAGES);

export default StringUtility;